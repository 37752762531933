import {
	Avatar,
	Box,
	Card,
	Heading,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Link,
	Input,
	useToast
} from '@chakra-ui/react';
import React, { useState, useEffect, useRef, ReactNode } from 'react';

import {
	observable, toJS, runInAction,
} from 'mobx';
import { inject, observer, useLocalStore } from 'mobx-react';
import { store } from 'Models/Store';
import {useNavigate} from 'react-router';
import { ModelCollection } from 'Views/Components/ModelCollection/ModelCollection';
import * as Models from 'Models/Entities';
import moment from 'moment';
import { ApiQueryParams, IOrderByCondition } from 'Views/Components/ModelCollection/ModelAPIQuery';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { PaginationQueryOptions } from 'Models/PaginationData';
import { IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import {
	holterReportStatusOptions,
	reportStatusOptions,
	reportStatusCombinedOptions,
	studyTypeOptions,
	holterReportStatus,
	studyType,
	procedureStatusTypeOptions,
} from 'Models/Enums';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import {
	Colors, Display, Sizes, Button,
} from 'Views/Components/Button/Button';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { ICollectionItemActionProps } from 'Views/Components/Collection/Collection';
import { SERVER_URL } from 'Constants';
import { debounce } from 'lodash';
import alert from 'Util/ToastifyUtils';
import axios from 'axios';
import { NewPatientModal } from '../Modals/NewPatientModal';
import ShareLinkModal from '../Modals/ShareLinkModal';
import { ContextMenu, IContextMenuActions } from 'Views/Components/ContextMenu/ContextMenu';
import { contextMenu } from 'react-contexify';
import { Combobox } from '../../Components/Combobox/Combobox';
import { gql, useQuery } from '@apollo/client';
import { StudyEntity, UserSiteAccessEntity } from 'Models/Entities';
import NewReportPreview from '../Study/NewReportPreview';
import {
	Select,
	Flex,
	Tabs as ChakraTabs,
	Tab as ChakraTab,
	TabList,
	FormControl,
	FormLabel,
	Radio,
	RadioGroup,
	Button as ChakraButton,
	ButtonGroup as ChakraButtonGroup,
	Text

} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import FileUploader, { fileType }  from 'Views/Components/FileUploadChakra/FileUploader';
import { AttachmentIcon, DeleteIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import TestDataPreview from '../Study/HolterComponents/TestDataPreview';
import * as Enums from 'Models/Enums';
import { StrictCommentGroupProps } from 'semantic-ui-react';


type transformFn<T> = (item: T, name: string) => (string | React.ReactNode);
interface IStudyCrudTileProps {
	orderBy?: IOrderByCondition<Models.StudyEntity>;
	perPage?: number;
	columns?: Array<ICollectionHeaderProps<Models.StudyEntity>>;
	filters?: Array<IFilter<Models.StudyEntity>>;
	actions?: Array<ICollectionItemActionProps<Models.StudyEntity>>;
	patient?: Models.PatientEntity;
	additionalActions?: React.ReactNode[];
	getAll?: boolean;
}

const GET_REPORTING_DOCTORS = gql`
  query GetUserSiteAccessEntities($siteGuids: [String!]!) {
    userSiteAccessEntitys(
      where: [
		{ path: "siteId", comparison: in, value: $siteGuids },
	 	{ path: "user.userType", comparison: equal, value: "reporting_doctor" }
	  ]
	  
    ) {
      user {
        name
      }
    }
  }
`;


type TestFormValues = {
	urgency: string;
	status: string;
	studyFile : fileType;
};

type StudyFormValues = {
	studyDate: string;
	studyType: Enums.studyType;
	reportStatus : string;
	accessionNumber: string;
};

type PatientFormValues = {
	leadECG : fileType;
	stressEcg : fileType;
	referral : fileType;
	echoReferral : fileType;
	diary : fileType;
};



const StudyCrudTile = inject('store')(observer((props: IStudyCrudTileProps) => {
	const [search, setSearch] = useState({ searchTerm: '' });
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [isUpdateStudyModalOpen, setIsUpdateStudyModalOpen] = useState(false);
	const [currentTab, setCurrentTab] = React.useState<string>('PATIENT_DATA');
	const toast = useToast();
	
	const [selectedStudy, setSelectedStudy] = useState<Models.StudyEntity | undefined>(undefined);
	const [studyFile, setStudyFile] = useState<fileType[]>([]);
	const [newPatientModalOpen, setNewPatientModalOpen] = useState<boolean>(false);
	
	const [leadECG, setLeadECG] = useState<fileType[]>([]);
	const [stressEcg, setStressEcg] = useState<fileType[]>([]);
	const [referral, setReferral] = useState<fileType[]>([]);
	const [echoReferral, setEchoReferral] = useState<fileType[]>([]);
	const [diary, setDiary] = useState<fileType[]>([]);

	const canDeleteFiles = store.userGroups.map(ug => ug.name).includes('SuperAdmin') || store.userSiteAccess.filter(site => site.accessLevel === 'FULL_ACCESS').length;
	const canEditStudyType = store.userSiteAccess.filter(site => site.accessLevel === 'FULL_ACCESS').length && (store.userType === 'CARDIAC_SCIENTIST' || store.userType === 'REPORTING_DOCTOR');
	
	const {
		register: registerTestForm,
		reset: resetTestForm,
		formState: { errors: testFormErrors },
		handleSubmit: handleTestFormSubmit,
		setValue: setTestValue,
		resetField: resetTestValue,
	} = useForm<TestFormValues>();

	const {
		register: registerPatientForm,
		reset: resetPatientForm,
		formState: { errors: patientFormErrors },
		handleSubmit: handlePatientFormSubmit,
		setValue: setPatientValue,
		resetField: resetPatientValue,
	} = useForm<PatientFormValues>();

	const {
		register: registerStudyForm,
		formState: { errors: studyFormErrors },
		handleSubmit: handleStudyFormSubmit,
		setValue: setStudyFormValue,
	} = useForm<StudyFormValues>();

	const navigate = useNavigate();

	const collectionRef = useRef<ModelCollection<Models.StudyEntity> | null>(null);
	const studyStore = useLocalStore(() => ({
		canShareStudy: store.userGroups.map(ug => ug.name)
			.includes('SuperAdmin') || 
			store.userType === 'SITE_ADMIN' || 
			store.userType === 'CARDIAC_SCIENTIST' || 
			store.userType === 'REPORTING_DOCTOR',
		isLoading: true,
	}));

	const pdfPagesState = useLocalStore(() => ({
		echoReportPDF: 1,
		echoReportTXT: 1,
		holterReportPDF: 1,
		holterReportTXT: 1,
	}));

	const files = useLocalStore(() => ({
		leadECG: new Models.StudyFileEntity(),
		ecgFileName: '',
		referral: new Models.StudyFileEntity(),
		referralFileName: '',
		diary: new Models.StudyFileEntity(),
		diaryFileName: '',
		echoReferral: new Models.StudyFileEntity(),
		echoReferralFileName: '',
		stressEcg: new Models.StudyFileEntity(),
		stressEcgFileName: '',
		holterReportPDF: '',
		holterReportTXT: '',
		echoReportPDF: '',
		echoReportTXT: '',
		previewType: 'PDF',
		preview: '',
	}));

	const testData = useLocalStore(() => ({
		status: holterReportStatusOptions.AWAITING_CARDIOLOGIST_CONFIRMATION,
		urgency: false,
		testData: new Models.StudyFileEntity(),
		testDataFileName: '',
		preview: '',
		previewType: 'PDF',
	}));

	const downloadModalStore = useLocalStore(() => ({
		pdf: false,
		text: false,
		reportTemplates: new Array(),
		selectedReportTemplate: '',
	}));

	const siteGuids = store.userSiteAccess.map(({ site }) => site.id);

	const { loading, error, data: reportingDoctors } = useQuery(GET_REPORTING_DOCTORS, {
		variables: {'siteGuids': siteGuids[0]}
	});

	useEffect(() => {
		const getNewStudies = setInterval(() => {
			collectionRef.current?.refetch();
		}, 60000);

		runInAction(() => {
			studyStore.isLoading = false;
		})

		return (): void => clearInterval(getNewStudies);
	}, []);

	if (loading) {
		return <></>;
	}

	const defaultOrderBy: IOrderByCondition<Models.StudyEntity> = { path: 'studyDate', descending: true };
	const orderBy: IOrderByCondition<Models.StudyEntity> | undefined = observable(defaultOrderBy || props.orderBy);
	const paginationQueryOptions: PaginationQueryOptions = observable(new PaginationQueryOptions());
	
	const sendToBucket = (study: StudyEntity) => {
		const { hL7ProviderId } = study.patient.site;
		
		if (hL7ProviderId) {
			axios.post(
				`${SERVER_URL}/api/entity/StudyEntity/hl7message/` + hL7ProviderId,
				{
					studyId: study.id,
					to: null,
					cc: null,
					documentTitle: study.studyType,
					correction: null,
					reportDetails: {
						firstName: study.patient.firstName,
						surname: study.patient.lastName,
						dob: new Date(study.patient.dob),
						requestDate: new Date(),
						observationDate: study.studyDate.toDateString(),
					},
				},
			).catch(error => alert(`HL7 Message Failed\n${error}`, 'error'));
		} else {
			alert('No provider ID')
		}
	}

	const createStructuredReport = async (study: StudyEntity) => {
		try {
			const response = await axios.post(`${SERVER_URL}/test`, { studyId: study.id }, {
				responseType: 'blob' 
			});

		
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'report.dcm'); 
			document.body.appendChild(link);
			link.click();

			if (link.parentNode) {
				link.parentNode.removeChild(link);
			}
		} catch (error) {
			console.error('Error downloading the report:', error);
		}
	};


	const toggleStudyUrgency = (study: Models.StudyEntity) => {
		store.modal.show('Confirmation',
			<div className="confirmation__modal">
				<h4>{`Do you want to set the study urgency to ${study.urgent ? 'Routine' : 'Urgent'}?`}</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'confirm' }}
						onClick={() => runInAction(() => {
							study.toggleUrgent();
							study.save();
							store.modal.hide();
						})}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const toggleStudySent = (study: Models.StudyEntity) => {
		store.modal.show('Confirmation',
			<div className="confirmation__modal">
				<h4>{'Do you want to set the study distributed status to sent?'}</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'confirm' }}
						onClick={() => runInAction(() => {
							study.toggleHL7MessageSent();
							study.save();
							store.modal.hide();
						})}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const deleteStudy = (study: Models.StudyEntity) => {
		store.modal.show('Confirmation',
			<div className="confirmation__modal">
				<h4>{`Do you want delete the ${studyTypeOptions[study.studyType]} study for ${study.patient.name}?`}</h4>
				<ButtonGroup alignment={Alignment.HORIZONTAL} className="confirmation-buttons">
					<Button
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'confirm' }}
						onClick={() => {
							const siteAccess = store.userSiteAccess.filter(usa => usa.siteId === study.patient.siteId);

							if (store.userGroups[0].name === 'SuperAdmin' || (siteAccess.length > 0 && siteAccess[0].accessLevel === 'FULL_ACCESS')) {
								study.delete().then(() => {
									collectionRef.current?.refetch();
									alert(
										<div className="delete-success">
											<p className="success-message">Deleted Study</p>
										</div>,
										'success',
									);
								}).catch(error => {
									alert(
										<div className="delete-failure">
											<p className="error-message">{error}</p>
										</div>,
										'error',
									);
								});
							}

							if (siteAccess.length > 0 && siteAccess[0].accessLevel !== 'FULL_ACCESS') {
								alert(
									<div className="delete-failure">
										<p className="error-message">You need full access to delete studies</p>
									</div>,
									'error',
								);
							}

							store.modal.hide();
						}}
					>
						Confirm
					</Button>
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>Cancel</Button>
				</ButtonGroup>
			</div>);
	};

	const shareStudy = (study: Models.StudyEntity) => {
		store.modal.show('Share Study', <ShareLinkModal {...props} study={study} />, { className: 'shareLink-wrapper' });
	};

	const tranformName: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => study.patient?.name;
	const tranformPatientId: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => study.patient?.patientId;
	const transformDob: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => moment(study.patient?.dob).format('DD/MM/YYYY');
	const transformStudyDate: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => moment(study.studyDate).format('DD/MM/YYYY');
	const transformStudyType: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => studyTypeOptions[study.studyType];
	const transformProcedureStatus: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => {
		const holter: studyType[] = ['HOLTERTWENTYFOURHR', 'HOLTERFORTYEIGHTHR', 'HOLTEREXTENDED', 'AMBULATORY_BP', 'EVENT_MONITOR',  'EXERCISE_STRESS_ECG'];

		if (study.procedureStatus) {
			return(
				<Button colors={Colors.Primary} display={Display.Solid} sizes={Sizes.Small}>
					{procedureStatusTypeOptions[study.procedureStatus]}
				</Button>
			)
		}

		if (holter.includes(study.studyType)) {
			const analysed: holterReportStatus[] = ['CONFIRMED_HOLTER', 'CONFIRMED_AND_COPIED'];
			const testDataFile = study.studyFiless.filter(sf => sf.fileType === 'HOLTER_REPORT');

			if (study.holterReport) {
				if (analysed.includes(study.holterReport?.reportStatus) || testDataFile.length > 0) {
					return (
						<Button colors={Colors.Success} display={Display.Solid} sizes={Sizes.Small}>
							Analysed
						</Button>
					);
				}
			}
			return (
				<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small}>
					Awaiting Analysis
				</Button>
			);
		}
		if (study.studyFilesCount > 0) {
			return (
				<Button colors={Colors.Success} display={Display.Solid} sizes={Sizes.Small}>
					{`${study.studyFilesCount} ${study.studyFilesCount === 1 ? 'Image' : 'Images'}`}
				</Button>
			);
		}
		return (
			<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small}>
				No Images Uploaded
			</Button>
		);


	};
	// const transformReportingDoctor: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => {
	// 	const reportingDoctor = study.doctorss.filter(d => d.doctorType === 'REPORTING')[0];
	//	
	// 	if (reportingDoctor) {
	// 		return `${reportingDoctor.prefix} ${reportingDoctor.firstName} ${reportingDoctor.lastName}`;
	// 	} else {
	// 		return 'Not Assigned'
	// 	 }
	// }

	const acceptedModalStudyTypes = ['HOLTERTWENTYFOURHR', 'HOLTERFORTYEIGHTHR', 'HOLTEREXTENDED', 'AMBULATORY_BP', 'EVENT_MONITOR',  'EXERCISE_STRESS_ECG'];

	const holterTypes = ['HOLTERTWENTYFOURHR','HOLTERFORTYEIGHTHR','HOLTEREXTENDED','AMBULATORY_BP','EVENT_MONITOR']
	const echoTypes = ['ECHO','STRESS_ECHO','DOBUTAMINE_STRESS_ECHO','TRANSOESOPHAGEAL_ECHO','PAEDIATRIC_ECHO']
	const ctTypes = ['CTCA','CTCS']

	const transformStudyStatus: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => {
		if (study.report) {
			if (study.report.reportStatus === 'NOT_REPORTED') {
				return (
					<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small}>
						{reportStatusOptions[study.report.reportStatus]}
					</Button>
				);
			}
			if (study.report.reportStatus === 'PROVISIONAL') {
				return (
					<Button colors={Colors.Warning} display={Display.Solid} sizes={Sizes.Small}>
						{reportStatusOptions[study.report.reportStatus]}
					</Button>
				);
			}
			if (study.report.reportStatus === 'CONFIRMED') {
				return (
					<Button colors={Colors.Success} display={Display.Solid} sizes={Sizes.Small}>
						{reportStatusOptions[study.report.reportStatus]}
					</Button>
				);
			}
			return (
				<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small}>
					NA
				</Button>
			);
		}
		if (study.holterReport) {
			if (study.holterReport.reportStatus === 'NOT_REPORTED_HOLTER') {
				return (
					<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small}>
						{holterReportStatusOptions[study.holterReport.reportStatus]}
					</Button>
				);
			}
			if (study.holterReport.reportStatus === 'AWAITING_CARDIOLOGIST_CONFIRMATION') {
				return (
					<Button colors={Colors.Warning} display={Display.Solid} sizes={Sizes.Small}>
						{holterReportStatusOptions[study.holterReport.reportStatus]}
					</Button>
				);
			}
			if (study.holterReport.reportStatus === 'CARDIOLOGIST_CHANGES_PENDING') {
				return (
					<Button colors={Colors.PrimaryLight} display={Display.Solid} sizes={Sizes.Small}>
						{holterReportStatusOptions[study.holterReport.reportStatus]}
					</Button>
				);
			}
			if (study.holterReport.reportStatus === 'CONFIRMED_HOLTER') {
				return (
					<Button colors={Colors.Success} display={Display.Solid} sizes={Sizes.Small}>
						{holterReportStatusOptions[study.holterReport.reportStatus]}
					</Button>
				);
			}
			if (study.holterReport.reportStatus === 'CONFIRMED_AND_COPIED') {
				return (
					<Button colors={Colors.Success} display={Display.Solid} sizes={Sizes.Small}>
						{holterReportStatusOptions[study.holterReport.reportStatus]}
					</Button>
				);
			}
			return (
				<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small}>
					NA
				</Button>
			);
		}
		return (
			<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small}>
				NA
			</Button>
		);
	};
	const transfromUrgency: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => {
		if (study.urgent) {
			return (
				<Button colors={Colors.Error} display={Display.Solid} sizes={Sizes.Small} icon={{ icon: 'chevron-down', iconPos: 'icon-right' }} onClick={() => runInAction(() => toggleStudyUrgency(study))}><span className="icon-left icon-clock">Urgent</span></Button>
			);
		}
		return (
			<Button colors={Colors.Grey} display={Display.Solid} sizes={Sizes.Small} icon={{ icon: 'chevron-down', iconPos: 'icon-right' }} buttonProps={{ id: 'routine' }} onClick={() => runInAction(() => toggleStudyUrgency(study))}>Routine</Button>
		);
	};
	const transformSite: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => study.patient?.site?.siteName;

	const transformAssignedDoctor: transformFn<Models.StudyEntity> = study => study.assignedDoctor;

	// do transform report distributed here
	const transformReportDistributed: transformFn<Models.StudyEntity> = (study: Models.StudyEntity) => {
		const siteAccess = store.userSiteAccess.filter(usa => usa.siteId === study.patient.siteId);
		if (study.hl7messagesent) {
			return (
				<Button colors={Colors.Success} display={Display.Solid} sizes={Sizes.Small} buttonProps={{ id: 'sent' }} disabled={true}>Sent</Button>
			);
		}
		if (siteAccess.length > 0 && siteAccess[0].accessLevel === 'FULL_ACCESS') {
			let confirmed = false;
			if (study.report) {
				confirmed = study.report.reportStatus === 'CONFIRMED';
			}
			if (study.holterReport) {
				confirmed = study.holterReport.reportStatus === 'CONFIRMED_HOLTER' || study.holterReport.reportStatus === 'CONFIRMED_AND_COPIED';
			}
			return (
				<Button colors={Colors.Grey} display={Display.Solid} sizes={Sizes.Small} icon={{ icon: 'chevron-down', iconPos: 'icon-right' }} buttonProps={{ id: 'notsent' }} onClick={() => runInAction(() => toggleStudySent(study))} disabled={!confirmed}>Not Sent</Button>
			);
		}
		return (
			<Button colors={Colors.Grey} display={Display.Solid} sizes={Sizes.Small} buttonProps={{ id: 'notsent' }} disabled>Not Sent</Button>
		);
	};

	const getHeaders = (): Array<ICollectionHeaderProps<Models.StudyEntity>> => [
		{
			name: 'viewButton',
			displayName: ' ',
			sortable: false,
			transformItem: (study: Models.StudyEntity) => (
				<Button
					colors={Colors.Primary}
					display={Display.Outline}
					sizes={Sizes.Small}
					buttonProps={{ id: 'view' }}
					onClick={() => navigate(`${SERVER_URL}/studypage/${study.id}`)}
				>
					View
				</Button>
			),
		},
		{
			name: 'patientName',
			displayName: 'Patient Name',
			sortable: true,
			transformItem: tranformName,
		},
		{
			name: 'patientId',
			displayName: 'Patient Id',
			sortable: true,
			transformItem: tranformPatientId,
		},
		{
			name: 'site',
			displayName: 'Site',
			sortable: true,
			transformItem: transformSite,
		},
		{
			name: 'dob',
			displayName: 'D.O.B',
			sortable: true,
			transformItem: transformDob,
		},
		{
			name: 'studyDate',
			displayName: 'Study Date',
			sortable: true,
			transformItem: transformStudyDate,
		},
		{
			name: 'studyType',
			displayName: 'Study Type',
			sortable: true,
			transformItem: transformStudyType,
		},
		{
			name: 'procedureStatus',
			displayName: 'Procedure Status',
			sortable: true,
			transformItem: transformProcedureStatus,
		},
		{
			name: 'studyStatus',
			displayName: 'Report Status',
			sortable: true,
			transformItem: transformStudyStatus,
		},
		{
			name: 'urgency',
			displayName: 'Urgency',
			sortable: true,
			transformItem: transfromUrgency,
		},
		{
			name:' assignedDoctor',
			displayName: 'Assigned Doctor',
			sortable: true,
			transformItem: transformAssignedDoctor,
		},
		{
			name: 'distributed',
			displayName: 'Distributed',
			sortable: true,
			transformItem: transformReportDistributed,
		},
	];

	const getMoreParamsFromFilters = (filters: Array<IFilter<Models.StudyEntity>>): ApiQueryParams => {
		const params = {};

		const studyDateFilter = filters.find(f => f.path === 'studyDate' && !!f.value1 && !!f.value2);
		if (studyDateFilter) {
			params['studyDateStart'] = studyDateFilter.value1;
			params['studyDateEnd'] = studyDateFilter.value2;
		}

		const studyTypeFilter = filters.find(f => f.path === 'studyType' && !!f.value1);
		if (studyTypeFilter) {
			if ((studyTypeFilter.value1 as string[]).includes('ECHO')) {
				params['echo'] = true;
			}
			if ((studyTypeFilter.value1 as string[]).includes('STRESS_ECHO')) {
				params['stressEcho'] = true;
			}
			if ((studyTypeFilter.value1 as string[]).includes('DOBUTAMINE_STRESS_ECHO')) {
				params['dobutamineStressEcho'] = true;
			}
			if ((studyTypeFilter.value1 as string[]).includes('TRANSOESOPHAGEAL_ECHO')) {
				params['transoesophagealEcho'] = true;
			}
			if ((studyTypeFilter.value1 as string[]).includes('PAEDIATRIC_ECHO')) {
				params['paediatricEcho'] = true;
			}
			if ((studyTypeFilter.value1 as string[]).includes('HOLTERTWENTYFOURHR')) {
				params['holter24H'] = true;
			}
			if ((studyTypeFilter.value1 as string[]).includes('HOLTERFORTYEIGHTHR')) {
				params['holter48H'] = true;
			}
			if ((studyTypeFilter.value1 as string[]).includes('HOLTEREXTENDED')) {
				params['holterExtended'] = true;
			}
			if ((studyTypeFilter.value1 as string[]).includes('AMBULATORY_BP')) {
				params['ABP'] = true;
			}
			if ((studyTypeFilter.value1 as string[]).includes('EVENT_MONITOR')) {
				params['eventMonitor'] = true;
			}
			if ((studyTypeFilter.value1 as string[]).includes('EXERCISE_STRESS_ECG')) {
				params['excersiseStressECG'] = true;
			}
			if ((studyTypeFilter.value1 as string[]).includes('CTCA')) {
				params['CTCA'] = true;
			}
			if ((studyTypeFilter.value1 as string[]).includes('CTCS')) {
				params['CTCS'] = true;
			}
		}

		const reportStatusFilter = filters.find(f => f.path === 'reportStatus' && !!f.value1);
		if (reportStatusFilter) {
			if ((reportStatusFilter.value1 as string[]).includes('NOT_REPORTED')) {
				params['notReported'] = true;
				params['holterNotReported'] = true;
			}
			if ((reportStatusFilter.value1 as string[]).includes('PROVISIONAL')) {
				params['provisional'] = true;
				params['holterAwaitingCardiologistConfirmation'] = true;
			}
			if ((reportStatusFilter.value1 as string[]).includes('CONFIRMED')) {
				params['confirmed'] = true;
				params['holterConfirmed'] = true;

			}
			if ((reportStatusFilter.value1 as string[]).includes('CARDIOLOGIST_CHANGES_PENDING')) {
				params['holterCardiologistChangesPending'] = true;
			}
			if ((reportStatusFilter.value1 as string[]).includes('CONFIRMED_AND_COPIED')) {
				params['holterConfirmedAndCopied'] = true;
			}
		}

		const urgencyFilter = filters.find(f => f.path === 'urgency' && !!f.value1);
		if (urgencyFilter) {
			if ((urgencyFilter.value1 as string[]).includes('urgent')) {
				params['urgent'] = true;
			}
			if ((urgencyFilter.value1 as string[]).includes('routine')) {
				params['routine'] = true;
			}
		}

		const siteFilter = filters.find(f => f.path === 'site' && !!f.value1 && (f.value1 as []).length !== 0);
		if (siteFilter) {
			params['siteFilter'] = JSON.stringify(siteFilter.value1);
		}

		const assignedDoctorFilter = filters.find(f => f.path === 'assignedDoctor' && !!f.value1 && (f.value1 as []).length !== 0);
		if (assignedDoctorFilter) {
			params['assignedDoctor'] = JSON.stringify(assignedDoctorFilter.value1);
		}

		return params;
	};

	const onSearchTriggered = (searchTerm: string) => {
		setSearch({ searchTerm });
	};

	const getMoreParams = (filters?: Array<IFilter<Models.StudyEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = (filters && filterApplied) ? getMoreParamsFromFilters(filters) : {};

		if (!store.userGroups.map(ug => ug.name).includes('SuperAdmin') && !store.userGroups.map(ug => ug.name).includes('Super Administrators')) {
			filterParams.siteIds = JSON.stringify(store.userSiteAccess.map(usa => usa.siteId));
		}

		if (props.patient) {
			filterParams.patientId = props.patient.id;
		}

		if (props.getAll) {
			filterParams.getAll = true;
		}

		return filterParams;
	};

	const onUploadSubmit = async (formData: PatientFormValues) => {
		if (formData.diary) {
			const blobData = await b64toUrl(formData.diary.data);
			const contentType = blobData.type || 'application/octet-stream';
			files.diary.studyFile = new File([blobData], formData.diary.name, { type: contentType });

			await files.diary.save(undefined, {
				contentType: 'multipart/form-data',
			}).then(() => {
				alert('Diary uploaded successfully', 'success');
				runInAction(() => {
					files.diary = new Models.StudyFileEntity();
					files.diaryFileName = '';
				});
			}).catch(() => {
				alert('Diary upload failed', 'error');
			});
		}

		if (formData.leadECG) {
			const blobData = await b64toUrl(formData.leadECG.data);
			const contentType = blobData.type || 'application/octet-stream';
			files.leadECG.studyFile = new File([blobData], formData.leadECG.name, { type: contentType });

			await files.leadECG.save(undefined, {
				contentType: 'multipart/form-data',
			}).then(() => {
				alert('12 lead ECG uploaded successfully', 'success');
				runInAction(() => {
					files.leadECG = new Models.StudyFileEntity();
					files.ecgFileName = '';
				});
			}).catch(() => {
				alert('12 lead ECG upload failed', 'error');
			});
		}

		if (formData.referral) {
			const blobData = await b64toUrl(formData.referral.data);
			const contentType = blobData.type || 'application/octet-stream';
			files.referral.studyFile = new File([blobData], formData.referral.name, { type: contentType });
			await files.referral.save(undefined, {
				contentType: 'multipart/form-data',
			}).then(() => {
				alert('Referral uploaded successfully', 'success');
				runInAction(() => {
					files.referral = new Models.StudyFileEntity();
					files.referralFileName = '';
				});
			}).catch(() => {
				alert('Referral upload failed', 'error');
			});
		}

		if (formData.echoReferral) {
			const blobData = await b64toUrl(formData.echoReferral.data);
			const contentType = blobData.type || 'application/octet-stream';
			files.echoReferral.studyFile = new File([blobData], formData.echoReferral.name, { type: contentType });

			await files.echoReferral.save(undefined, {
				contentType: 'multipart/form-data',
			}).then(() => {
				alert('Echo referral uploaded successfully', 'success');
				runInAction(() => {
					files.echoReferral = new Models.StudyFileEntity();
					files.echoReferralFileName = '';
				});
			}).catch(() => {
				alert('Echo referral upload failed', 'error');
			});
		}

		if (formData.stressEcg) {
			const blobData = await b64toUrl(formData.stressEcg.data);
			const contentType = blobData.type || 'application/octet-stream';
			files.stressEcg.studyFile = new File([blobData], formData.stressEcg.name, { type: contentType });

			await files.stressEcg.save(undefined, {
				contentType: 'multipart/form-data',
			}).then(() => {
				alert('Stress ECG uploaded successfully', 'success');
				runInAction(() => {
					files.stressEcg = new Models.StudyFileEntity();
					files.stressEcgFileName = '';
				});
			}).catch(() => {
				alert('Stress ECG upload failed', 'error');
			});
		}

		if (collectionRef.current) {
			collectionRef.current.refetch();
		}

		setIsUploadModalOpen(false);
	};

	const b64toUrl = async (base64Data: string) => {
		const response = await fetch(base64Data);
		const blob = await response.blob();
		return blob;
	};

	const onStudyFormSubmit = (formData: StudyFormValues) => {
		if (selectedStudy) {
			runInAction(() => {
				selectedStudy.studyType = formData.studyType;
				selectedStudy.accessionNumber = formData.accessionNumber;
				selectedStudy.studyDate = new Date(formData.studyDate);
				
				selectedStudy.save().then(()=> {
					toast({
						title: 'Study Saved',
						description: 'Study Updated',
						position: 'bottom-right',
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
					setIsUpdateStudyModalOpen(false);
				})
			});
		}
	}

	const sendHl7 = async (study: StudyEntity) => {
		const { hL7ProviderId } = study.patient.site;

		if (hL7ProviderId) {
			axios.post(
				`${SERVER_URL}/api/entity/StudyEntity/hl7message/` + hL7ProviderId,
				{
					studyId: study.id,
					to: null,
					cc: null,
					documentTitle: study.studyType,
					correction: null,
					reportDetails: {
						firstName: study.patient.firstName,
						surname: study.patient.lastName,
						dob: new Date(study.patient.dob).toISOString(),
						requestDate: new Date().toISOString(),
						observationDate: new Date(study.studyDate).toISOString(),
					},
				},
			)
		}
	};

	const onHolterReportSubmit = async (formData: TestFormValues) => {
		const { study } = toJS(testData.testData);

		if (formData.studyFile !== undefined) {
			const blobData = await b64toUrl(formData.studyFile.data);
			const contentType = blobData.type || 'application/octet-stream';
			testData.testData.studyFile = new File([blobData], formData.studyFile.name, {type: contentType});

			await testData.testData.save({ study: { holterReport: {} } }, {
				contentType: 'multipart/form-data',
			}).then(() => {
				alert('Test Data uploaded successfully', 'success');
				runInAction(() => {
					testData.testData = new Models.StudyFileEntity();
					testData.testDataFileName = '';
				});
			}).catch(() => {
				alert('Test Data upload failed', 'error');
			});
		}

		if (study.holterReport?.reportStatus === 'CONFIRMED_HOLTER') {
			axios.get(`${SERVER_URL}/api/entity/StudyEntity/HolterPdf/${study.id}`).then(() => {
				alert('Holter report updated successfully', 'success');
			})
		}

		if (collectionRef.current) {
			collectionRef.current.refetch();
		}
		if (study.holterReport) {
			const holterReport = new Models.HolterReportEntity(study.holterReport);
			if (holterReport) {
				holterReport.reportStatus = formData.status as holterReportStatus;
			}
			const typedStudy = new Models.StudyEntity(study);

			if (typedStudy.urgent !== (formData.urgency === 'Urgent')) {
				typedStudy.urgent = formData.urgency === 'Urgent';
				await typedStudy.save().then( () => {
					alert('Study updated successfully', 'success');
				});
			}
			await holterReport.save().then( () => {
				alert('Holter Report updated successfully', 'success');
			});

		}

		console.log(study.patient.site.hL7ProviderId)
		if (study.patient.site.hL7ProviderId) {
			await sendHl7(study);
		}

		setIsUploadModalOpen(false);
	};

	const uploadModal = (item: Models.StudyEntity) => {
		setSelectedStudy(item);
		runInAction(() => {
			if (acceptedModalStudyTypes.includes(item.studyType)) {
				files.leadECG = new Models.StudyFileEntity();
				files.referral = new Models.StudyFileEntity();
				files.diary = new Models.StudyFileEntity();
				files.stressEcg = new Models.StudyFileEntity();
				testData.testData = new Models.StudyFileEntity();
				// TAG
				files.leadECG.fileType = 'HOLTER_ECG';
				files.leadECG.study = item;
				files.leadECG.studyId = item.id;
				files.leadECG.status = 'AVAILABLE';

				files.referral.fileType = 'HOLTER_REFERRAL';
				files.referral.study = item;
				files.referral.studyId = item.id;
				files.referral.status = 'AVAILABLE';

				files.diary.fileType = 'HOLTER_DIARY';
				files.diary.study = item;
				files.diary.studyId = item.id;
				files.diary.status = 'AVAILABLE';

				testData.testData.fileType = 'HOLTER_REPORT';
				testData.testData.study = item;
				testData.testData.studyId = item.id;
				testData.testData.status = 'AVAILABLE';

				files.stressEcg.fileType = 'STRESS_ECG_REPORT';
				files.stressEcg.study = item;
				files.stressEcg.studyId = item.id;
				files.stressEcg.status = 'AVAILABLE';
			} else {
				files.echoReferral = new Models.StudyFileEntity();
				files.echoReferral.fileType = 'ECHO_REFERRAL';
				files.echoReferral.study = item;
				files.echoReferral.studyId = item.id;
				files.echoReferral.status = 'AVAILABLE';

				files.leadECG = new Models.StudyFileEntity();
				files.leadECG.fileType = 'HOLTER_ECG';
				files.leadECG.study = item;
				files.leadECG.studyId = item.id;
				files.leadECG.status = 'AVAILABLE';

				files.referral.studyFileId = '';
				files.diary.studyFileId = '';
				testData.testData.studyFileId = '';

				files.stressEcg = new Models.StudyFileEntity();
				files.stressEcg.fileType = 'STRESS_ECG_REPORT';
				files.stressEcg.study = item;
				files.stressEcg.studyId = item.id;
				files.stressEcg.status = 'AVAILABLE';
			}
		});

		resetTestForm();
		resetPatientForm();

		// Check if already exists 
		axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/StudyFileByStudy/${item.id}`)
			.then(res => {
				res.data.forEach((file: Models.StudyFileEntity) => {
					if (file.fileType === 'HOLTER_DIARY') {
						runInAction(() => {
							files.diary = new Models.StudyFileEntity(file);
						});

						axios.get(`${SERVER_URL}/api/files/metadata/${files.diary.studyFileId}`)
							.then(diary => {
								setDiary([diary.data]);
								runInAction(() => {
									files.diaryFileName = diary.data.fileName;
								});
							});
					}

					if (file.fileType === 'HOLTER_ECG') {
						runInAction(() => {
							files.leadECG = new Models.StudyFileEntity(file);
							axios.get(`${SERVER_URL}/api/files/metadata/${files.leadECG.studyFileId}`)
								.then(ecg => {
									setLeadECG([ecg.data]);
									runInAction(() => {
										files.ecgFileName = ecg.data.fileName;
									});
								});
						});
					}

					if (file.fileType === 'HOLTER_REFERRAL') {
						runInAction(() => {
							files.referral = new Models.StudyFileEntity(file);
							axios.get(`${SERVER_URL}/api/files/metadata/${files.referral.studyFileId}`)
								.then(referral => {
									setReferral([referral.data]);
									runInAction(() => {
										files.referralFileName = referral.data.fileName;
									});
								});
						});
					}

					if (file.fileType === 'HOLTER_REPORT') {
						runInAction(() => {
							testData.testData = new Models.StudyFileEntity(file);
							testData.testData.study = item;
							testData.testData.studyId = item.id;
							axios.get(`${SERVER_URL}/api/files/metadata/${testData.testData.studyFileId}`)
								.then(testDataFile => {
									setStudyFile([testDataFile.data]);
									runInAction(() => {
										testData.testDataFileName = testDataFile.data.fileName;
									});
								});
						});
					}

					if (file.fileType === 'ECHO_REFERRAL') {
						runInAction(() => {
							files.echoReferral = new Models.StudyFileEntity(file);
							axios.get(`${SERVER_URL}/api/files/metadata/${files.echoReferral.studyFileId}`)
								.then(echoReferral => {
									setEchoReferral([echoReferral.data]);
									runInAction(() => {
										files.echoReferralFileName = echoReferral.data.fileName;
									});
								});
						});
					}

					if (file.fileType === 'STRESS_ECG_REPORT') {
						runInAction(() => {
							files.stressEcg = new Models.StudyFileEntity(file);
							axios.get(`${SERVER_URL}/api/files/metadata/${files.stressEcg.studyFileId}`)
								.then(stressECG => {
									setStressEcg([stressECG.data]);
									runInAction(() => {
										files.stressEcgFileName = stressECG.data.fileName;
									});
								});
						});
					}
				});
			}).finally(() => {
				setCurrentTab('PATIENT_DATA')
				setStudyFile([]);
				setLeadECG([]);
				setStressEcg([]);
				setReferral([]);
				setEchoReferral([]);
				setDiary([]);
				setIsUploadModalOpen(true)
			});
	};

	const previewModal = async (item: Models.StudyEntity) => {
		store.modal.show('Preview', <NewReportPreview study={item} />, { className: 'preview-wrapper' });

		// const holterTypes: studyType[] = ['HOLTERTWENTYFOURHR', 'HOLTERFORTYEIGHTHR', 'HOLTEREXTENDED', 'AMBULATORY_BP', 'EVENT_MONITOR', 'EXERCISE_STRESS_ECG'];
		// if (holterTypes.includes(item.studyType)) {
		// 	await axios.get(`${SERVER_URL}/api/entity/StudyEntity/TestDataTXT/${item.id}`)
		// 		.then(async res => runInAction(() => files.holterReportTXT = res.data))
		// 		.then(async () => {
		// 			await axios.get(`${SERVER_URL}/api/entity/StudyEntity/TestDataPDF/${item.id}`)
		// 				.then(async res => runInAction(() => files.holterReportPDF = `data:application/pdf;base64,${res.data}`))
		// 				.catch(() => alert('Failed to fetch test data pdf', 'error'));
		// 		})
		// 		.then(() => store.modal.show('Preview', <PreviewModal files={files} pages={pdfPagesState} reportReference="holterReport" />, { className: 'preview-wrapper' }))
		// 		.catch(() => alert('Could not retrieve holter report txt', 'error'));
		// } else if (item.patient.site.advancedReportBuilder && (item.report?.advancedreportstring !== null && item.report?.advancedreportstring !== '')) {
		// 	files.echoReportPDF = '';
		// 	files.echoReportTXT = '';
		// 	store.modal.show('Preview', <TemplatePreviewModal files={files} pages={pdfPagesState} reportReference="echoReport" study={item} />, { className: 'preview-wrapper' });
		// } else {
		// 	await axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportTXTBasic/${item.id}`)
		// 		.then(async res => runInAction(() => files.echoReportTXT = res.data))
		// 		.then(async () => {
		// 			await axios.get(`${SERVER_URL}/api/entity/StudyEntity/EchoReportPDFBasic/${item.id}`)
		// 				.then(async res => runInAction(() => files.echoReportPDF = `data:application/pdf;base64,${res.data}`))
		// 				.catch(() => alert('Failed to fetch echo report pdf', 'error'));
		// 		})
		// 		.then(() => store.modal.show('Preview', <PreviewModal basic files={files} pages={pdfPagesState} reportReference="echoReport" />, { className: 'preview-wrapper' }))
		// 		.catch(() => alert('Could not retrieve echo report txt', 'error'));
		// }
	};

	const getReportTemplates = (item: Models.StudyEntity): any => {
		store.apolloClient.query({
			query: Models.SiteEntity.fetchSingleSite(),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: item.patient.siteId,
				}],
			},
		}).then(res => {
			runInAction(() => {
				if (downloadModalStore.reportTemplates.length === 0) {
					downloadModalStore.reportTemplates.push({ display: 'Default', value: 'Default' });
					const site = new Models.SiteEntity({ ...res.data.siteEntity });
					Object.keys(site.reportTemplates).forEach((template: string) => {
						if (!downloadModalStore.reportTemplates.includes(site.reportTemplates[template].templateName)) {
							downloadModalStore.reportTemplates.push({
								display: site.reportTemplates[template].templateName,
								value: site.reportTemplates[template].templateName,
							});
						}
					})
					if (downloadModalStore.reportTemplates.length > (site.reportTemplates.length + 1)) {
						downloadModalStore.reportTemplates.pop();
					}
				}
			})
		}).catch(error => {
			console.error(error);
		});
	}

	const downloadModal = (item: Models.StudyEntity) => {
		getReportTemplates(item);

		runInAction(() => {
			downloadModalStore.selectedReportTemplate = item.selectedReportTemplate;
		})

		store.modal.show('Preview',
			<div className="file-download-modal">
				<h4>Download Report</h4>
				<p>Select a file type to download the report.</p>
				<div className="file-download-options">
					<span className="option-group">
						<label htmlFor="pdf">PDF</label>
						<input type="checkbox" name="pdf" id="pdf" onChange={() => runInAction(() => downloadModalStore.pdf = !downloadModalStore.pdf)} />
					</span>
					<span className="option-group">
						<label htmlFor="txt">TXT</label>
						<input type="checkbox" name="txt" id="txt" onChange={() => runInAction(() => downloadModalStore.text = !downloadModalStore.text)} />
					</span>
					<span className="option-group">
						<Combobox
							model={item}
							modelProperty="value"
							label="Report Template"
							placeholder={downloadModalStore.selectedReportTemplate}
							options={downloadModalStore.reportTemplates}
							onAfterChange={(event): void => runInAction(() => {
								downloadModalStore.selectedReportTemplate = event.currentTarget.textContent || 'Default';
							})}
						/>
					</span>
				</div>
				<ButtonGroup alignment={Alignment.HORIZONTAL}>
					<Button
						colors={Colors.Secondary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={() => store.modal.hide()}
					>
						Close
					</Button>
					<Button
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'download' }}
						onClick={() => {
							if (!downloadModalStore.selectedReportTemplate) {
								alert('Please select the template for the report using the dropdown.', 'error');
								return;
							}

							let reportData;
							let numberOfKeys = 0;

							try {
								reportData = JSON.parse(item.report?.reportDataGenerated ?? '{}');
								numberOfKeys = Object.keys(reportData?.observation || {}).length;
							} catch (error) {
								console.error('Error parsing reportDataGenerated:', error);
							}


							if (downloadModalStore.pdf) {
								if (item.studyType !== 'HOLTERTWENTYFOURHR' && item.studyType !== 'HOLTEREXTENDED' && item.studyType !== 'HOLTERFORTYEIGHTHR') {
									if ((item.patient.site.advancedReportBuilder &&
										item.report?.reportDataGenerated &&
										item.report.reportDataGenerated.trim() !== '') || numberOfKeys > 1  ) {
										window.open(`${SERVER_URL}/createMeasurementsPDFFromTemplate/${item.id}/${downloadModalStore.selectedReportTemplate}`, '_blank');
									} else {
										window.open(`${SERVER_URL}/createMeasurementsPDFBasic/${item.id}`, '_blank');
									}
								} else {
									axios.get(`${SERVER_URL}/api/entity/StudyFileEntity/StudyFileByStudy/${item.id}`)
										.then(async res => {
											await res.data.forEach((file: Models.StudyFileEntity) => {
												if (file.fileType === 'HOLTER_REPORT') {
													window.open(`${SERVER_URL}/api/files/${file.studyFileId}?download=true`, '_blank');
												}
											});
										});
								}
							}
							if (downloadModalStore.text) {
								if (item.studyType !== 'HOLTERTWENTYFOURHR' && item.studyType !== 'HOLTEREXTENDED' && item.studyType !== 'HOLTERFORTYEIGHTHR') {
									if ((item.patient.site.advancedReportBuilder &&
										item.report?.reportDataGenerated &&
										item.report.reportDataGenerated.trim() !== '') || numberOfKeys > 1  ) {
										window.open(`${SERVER_URL}/createReportTXT/${item.id}/${downloadModalStore.selectedReportTemplate}`, '_blank');
									} else {
										window.open(`${SERVER_URL}/createReportTXTBasic/${item.id}`, '_blank');
									}
								} else {
									window.open(`${SERVER_URL}/api/entity/HolterReportEntity/DownloadTXT/${item.id}`, '_blank');
								}
							}
							runInAction(() => {
								downloadModalStore.text = false;
								downloadModalStore.pdf = false;
							});
							store.modal.hide();
						}}
					>
						Download
					</Button>
				</ButtonGroup>
			</div>,
		);
	};

	const openDocumentModal = (item: Models.StudyEntity) => {
		const siteAccess = store.userSiteAccess.filter(usa => usa.siteId === item.patient.siteId);
		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin') || (siteAccess.length > 0 && siteAccess[0].accessLevel === 'FULL_ACCESS')) {
			uploadModal(item);
		}
	};

	const openPreviewModal = (item: Models.StudyEntity) => {
		setSelectedStudy(item);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};
	const openDownloadModal = (item: Models.StudyEntity) => {
		downloadModal(item);
	};

	const editStudy = (item: Models.StudyEntity) => {
		if (!store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			runInAction(() => {
				setSelectedStudy(item);
				setStudyFormValue('studyDate',  moment(item.studyDate).format('YYYY-MM-DD'));
				setStudyFormValue('studyType',  item.studyType);
				setStudyFormValue('accessionNumber',  item.accessionNumber);
				// if (acceptedModalStudyTypes.includes(item.studyType) && item.holterReport) {
				// 	setStudyFormValue('reportStatus', item.holterReport.reportStatus);
				// } else if (item.report) {
				// 	setStudyFormValue('reportStatus', item.report.reportStatus);
				// }
				setIsUpdateStudyModalOpen(true);
			});
		} else {
			navigate(`${SERVER_URL}/admin/studyentity/edit/${item.id}`);
		}
	}

	const getContextMenuActions = (study: Models.StudyEntity): IContextMenuActions => {
		const contextMenuActions = [] as IContextMenuActions;
		if (store.userSiteAccess.filter(siteAccess => (study.patient.site.id === siteAccess.site.id) && siteAccess.accessLevel === 'FULL_ACCESS').length || store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			contextMenuActions.push(
				{
					label: 'Edit', icon: 'edit', iconPos: 'icon-left', onClick: () => editStudy(study),
				}
			);
		}
		contextMenuActions.push(			{
			label: 'Delete', icon: 'bin-full', iconPos: 'icon-left', onClick: (): void => deleteStudy(study),
		})
		if (study.report || study.holterReport) {
			if (study.report?.reportStatus === 'PROVISIONAL' || study.report?.reportStatus === 'CONFIRMED' || study.holterReport?.reportStatus === 'AWAITING_CARDIOLOGIST_CONFIRMATION' || study.holterReport?.reportStatus === 'CARDIOLOGIST_CHANGES_PENDING' || study.holterReport?.reportStatus === 'CONFIRMED_AND_COPIED' || study.holterReport?.reportStatus === 'CONFIRMED_HOLTER') {
				contextMenuActions.push(
					{
						label: 'Preview', icon: 'edit', iconPos: 'icon-left', onClick: (): void => openPreviewModal(study),
					},
					{
						label: 'Download Report', icon: 'download', iconPos: 'icon-left', onClick: () => openDownloadModal(study),
					},
				);
			}
		}

		if (studyStore.canShareStudy) {
			const fullAccessSites = store.userSiteAccess.filter(usa => usa.accessLevel === 'FULL_ACCESS').map(usa => usa.siteId);
			if (fullAccessSites.includes(study.patient?.siteId) || store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
				contextMenuActions.push({
					label: 'Share Study', icon: 'share', iconPos: 'icon-left', onClick: () => shareStudy(study),
				});
			}
		}

		let canSendReport = false;

		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			contextMenuActions.push({
				label: 'Send to HL7 Bucket',
				icon: 'bin-full',
				iconPos: 'icon-left',
				onClick: (): void => sendToBucket(study),
			})
			
			contextMenuActions.push({
				label: 'Create SR FIle',
				icon: 'bin-full',
				iconPos: 'icon-left',
				onClick: (): void => {
					createStructuredReport(study);
					return;
				},
			})
		}

		if (!store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {

			if (study && study.patient && study.patient.site &&
				study.patient.site.hl7MessagingEnabled &&
				store && store.userSiteAccess &&
				store.userSiteAccess.some(usa => usa.siteId === study.patient.siteId && usa.hl7MessagingEnabled)) {
				if (study.report) {
					if (study.report.reportStatus === 'CONFIRMED') {
						canSendReport = true;
					}
				}
				if (study.holterReport) {
					if (study.holterReport.reportStatus === 'CONFIRMED_HOLTER' || study.holterReport.reportStatus === 'CONFIRMED_AND_COPIED') {
						canSendReport = true;
					}
				}
			}
		}

		if (canSendReport) {
			contextMenuActions.push({
				label: 'Send Report', icon: 'file', iconPos: 'icon-left', onClick: (): void => navigate(`${SERVER_URL}/distributereport/${study.id}`),
			});
		}

		return contextMenuActions;
	};

	const getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<Models.StudyEntity>> = [];

		tableActions.push({
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			action: item => {},
			label: 'Upload Documents',
			customButton: item => {
				let buttonColor = Colors.Error;

				const holterButtonText: ReactNode[] = [];
				const echoButtonText: ReactNode[] = [];

				if (item.studyType === 'ECHO') {
					if (item.ecgExist) {
						echoButtonText.push((<span className="doc uploaded">ECG</span>));
					} else {
						echoButtonText.push((<span className="doc">ECG</span>));
					}

					if (item.referralExist) {
						echoButtonText.push((<span className="doc uploaded">Referral</span>));
					} else {
						echoButtonText.push((<span className="doc">Referral</span>));
					}

					buttonColor = item.studyFiless.filter(sf => sf.fileType === 'ECHO_REFERRAL').length > 0 ? Colors.Success : Colors.Error;
				} else if (item.studyType === 'STRESS_ECHO') {
					if (item.referralExist) {
						echoButtonText.push((<span className="doc uploaded">Referral</span>));
					} else {
						echoButtonText.push((<span className="doc">Referral</span>));
					}

					buttonColor = item.studyFiless.filter(sf => sf.fileType === 'ECHO_REFERRAL').length > 0 ? Colors.Success : Colors.Error;
				} else {
					buttonColor = item.referralExist && item.ecgExist && item.diaryExist ? Colors.Success : Colors.Error;
					if (item.ecgExist) {
						holterButtonText.push((<span className="doc uploaded">ECG</span>));
					} else {
						holterButtonText.push((<span className="doc">ECG</span>));
					}
					if (item.referralExist) {
						holterButtonText.push((<span className="doc uploaded">Referral</span>));
					} else {
						holterButtonText.push((<span className="doc">Referral</span>));
					}
					if (item.diaryExist) {
						holterButtonText.push((<span className="doc uploaded">Diary</span>));
					} else {
						holterButtonText.push((<span className="doc">Diary</span>));
					}
				}

				return (
					<Button
						colors={buttonColor}
						display={Display.Solid}
						sizes={Sizes.Small}
						buttonProps={{ id: 'upload-documents' }}
						onClick={(): void => openDocumentModal(item)}
						className="upload-docs-button"
					>
						{item.studyType === 'ECHO' || item.studyType === 'STRESS_ECHO' ? <>{echoButtonText}</> : <>{holterButtonText}</>}
					</Button>
				);
			},
		});

		tableActions.push({
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			action: item => {},
			label: 'Actions',
			customButton: (study: Models.StudyEntity) => (
				<>
					<Button
						colors={Colors.Primary}
						display={Display.Outline}
						sizes={Sizes.Small}
						buttonProps={{ id: 'confirm' }}
						onClick={e => contextMenu.show({ event: e, id: `context-menu-${study.id}` })}
					>
						Actions
					</Button>
					<ContextMenu
						menuId={`context-menu-${study.id}`}
						actions={getContextMenuActions(study)}
					/>
				</>
			),
		});

		return tableActions;
	};

	const additionalActions = (): React.ReactNode[] => {
		const usersWithAddPatient = ['SITE_ADMIN', 'SITE_USER', 'CARDIAC_SCIENTIST'];
		if (usersWithAddPatient.includes(store.userType) || store.userGroups[0].name === 'SuperAdmin') {
			return [
				<Button
					key="create"
					className={Display.Solid}
					icon={{ icon: 'create', iconPos: 'icon-left' }}
					buttonProps={{ onClick: () =>  setNewPatientModalOpen(true) }}
				>
					New Patient
				</Button>,
			];
		}
		return [];
	};

	const getFilters = (): Array<IFilter<Models.StudyEntity>> => {
		const studyDateFilter: IFilter<Models.StudyEntity> = {
			path: 'studyDate',
			comparison: 'range',
			value1: undefined,
			value2: undefined,
			active: false,
			displayType: 'datepicker',
			displayName: 'Study Date',
		};

		const studyTypeFilter: IFilter<Models.StudyEntity> = {
			path: 'studyType',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Study Type',
			enumResolveFunction: makeEnumFetchFunction(studyTypeOptions),
		};

		const siteOptions = {};
		const assignedDoctors = {};

		reportingDoctors?.userSiteAccessEntitys?.map((usa: UserSiteAccessEntity) => {
			assignedDoctors['Dr ' + usa.user.name] = 'Dr ' + usa.user.name;
		});

		if (store.userGroups.map(ug => ug.name).includes('SuperAdmin')) {
			store.sites.forEach(site => {
				if (site.id && site.siteName) {
					siteOptions[site.id] = site.siteName;
				}
			});
		} else {
			store.userSiteAccess.forEach(usa => {
				const jsUsa = toJS(usa);

				if (jsUsa.siteId && jsUsa.site) {
					if (jsUsa.site.siteName) {
						siteOptions[jsUsa.siteId] = jsUsa.site.siteName;
					}
				}
			});
		}

		const siteFilter: IFilter<Models.PatientEntity> = {
			path: 'site',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Site',
			enumResolveFunction: makeEnumFetchFunction(siteOptions),
		};

		const reportStatusFilter: IFilter<Models.StudyEntity> = {
			path: 'reportStatus',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Report Status',
			enumResolveFunction: [...makeEnumFetchFunction(reportStatusCombinedOptions)].sort((a, b) => {
				if (a.display[0] === b.display[0]) {
					if (a.display[1] === b.display[1]) {
						return 0;
					}
					if (a.display[1] < b.display[1]) {
						return 1;
					}
					return -1;
				}
				if (a.display[0] < b.display[0]) {
					return 1;
				}
				return -1;
			}),
		};

		const assignedDoctorFilter: IFilter<Models.StudyEntity> = {
			path: 'assignedDoctor',
			displayName: 'Assigned Doctor',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			enumResolveFunction: makeEnumFetchFunction(assignedDoctors),
		}

		const urgencyFilter: IFilter<Models.StudyEntity> = {
			path: 'urgency',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Urgent',
			enumResolveFunction: makeEnumFetchFunction({ urgent: 'Urgent', routine: 'Routine' }),
		};

		return [studyDateFilter, studyTypeFilter, siteFilter, reportStatusFilter, urgencyFilter, assignedDoctorFilter];
	};

	const deleteFile = async (fileTyoe: string) => {
		if (fileTyoe === 'referral') {
			setReferral([]);
			resetPatientValue('referral');
			await files.referral.delete();
			runInAction(() => {
				files.referral = new Models.StudyFileEntity();
			})
		} else if (fileTyoe === 'leadECG') {
			setLeadECG([]);
			resetPatientValue('leadECG');
			await files.leadECG.delete();
			runInAction(() => {
				files.leadECG = new Models.StudyFileEntity();
			})
		} else if (fileTyoe === 'stressEcg') {
			setStressEcg([]);
			resetPatientValue('stressEcg');
			await files.stressEcg.delete();
			runInAction(() => {
				files.stressEcg = new Models.StudyFileEntity();
			})
		} else if (fileTyoe === 'echoReferral') {
			setEchoReferral([]);
			resetPatientValue('echoReferral');
			await files.echoReferral.delete();
			runInAction(() => {
				files.echoReferral = new Models.StudyFileEntity();
			})
		} else if (fileTyoe === 'diary') {
			setDiary([]);
			resetPatientValue('diary');
			await files.diary.delete();
			runInAction(() => {
				files.diary = new Models.StudyFileEntity();
			})
		} else if (fileTyoe === 'studyFile') {
			setStudyFile([]);
			resetTestValue('studyFile');
			await testData.testData.delete();
			runInAction(() => {
				testData.testData = new Models.StudyFileEntity();
			})
		}

	}

	return (
		!studyStore.isLoading ? (
			<>
				<div className="study__crud-list">
					<ModelCollection
						ref={collectionRef}
						url="/api/entity/StudyEntity/study_list"
						searchStr={search.searchTerm}
						isApiQuery
						orderBy={orderBy}
						model={Models.StudyEntity}
						headers={props.columns ? props.columns : getHeaders()}
						selectableItems={false}
						onSearchTriggered={onSearchTriggered}
						onSearchChange={debounce(onSearchTriggered, 200)}
						perPage={paginationQueryOptions.perPage}
						idColumn="id"
						dataFields={row => ({
							created: moment(row.created).format('YYYY-MM-DD'),
							modified: moment(row.modified).format('YYYY-MM-DD'),
						})}
						getMoreParams={getMoreParams}
						filters={props.filters ? props.filters : getFilters()}
						actions={props.actions ? props.actions : getActions()}
						additionalActions={props.additionalActions ? props.additionalActions : additionalActions()}
						dateFormat="d/m/Y"
						altFormat="d/m/Y"
						customActionsHeaderText="Upload Documents"
					/>
					<NewPatientModal
						isOpen={newPatientModalOpen}
						onClose={()=>{setNewPatientModalOpen(false)}}
						onConfirm={()=>{setNewPatientModalOpen(false)}}
					/>
				</div>
				<Modal isOpen={isModalOpen} onClose={handleCloseModal} size="xl" isCentered>
					<ModalOverlay />
					<ModalContent maxH="100%" maxW="50%" h="80vh">
						<ModalHeader>Preview Report</ModalHeader>
						<ModalCloseButton />
						<ModalBody maxHeight="80vh" overflowY="auto" w="50vw">
							<NewReportPreview study={selectedStudy} enableText />
						</ModalBody>
						<ModalFooter>
							<Button onClick={handleCloseModal}>
								Close
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
				<Modal isOpen={isUploadModalOpen} onClose={() => {setIsUploadModalOpen(false)}} size="xl" isCentered>
					<ModalOverlay />
					<ModalContent  maxH="100%" maxW="50%">

						<ModalHeader>Upload Data</ModalHeader>
						<ModalCloseButton />
						<ModalBody  maxHeight="80vh" overflowY="auto" w="50vw">
							<ChakraTabs   width="100%">
								<TabList flexWrap="wrap">
									<ChakraTab key="PATIENT_DATA" onClick={() => setCurrentTab('PATIENT_DATA')}>Patient Data</ChakraTab>
									{selectedStudy && ['HOLTERTWENTYFOURHR','HOLTERFORTYEIGHTHR','HOLTEREXTENDED','AMBULATORY_BP','EVENT_MONITOR'].includes(selectedStudy.studyType) ?
										<ChakraTab key="TEST_DATA" onClick={() => setCurrentTab('TEST_DATA')}>Test Data</ChakraTab>
										: ''}
								</TabList>
							</ChakraTabs>

							<form onSubmit={handlePatientFormSubmit(onUploadSubmit)}>

								<Flex display={currentTab === 'PATIENT_DATA' ? 'flex' : 'none'} flexDirection="column">

									<Flex>
										<FormControl
											display={selectedStudy && ['ECHO','TRANSOESOPHAGEAL_ECHO','PAEDIATRIC_ECHO','STRESS_ECHO','DOBUTAMINE_STRESS_ECHO','HOLTERTWENTYFOURHR','HOLTERFORTYEIGHTHR','HOLTEREXTENDED','AMBULATORY_BP','EVENT_MONITOR'].includes(selectedStudy.studyType) ? 'flex' : 'none'}
											flexDir="column"
											isInvalid={!!patientFormErrors.leadECG}
											width="100%"
											margin="8px 0px"
										>
											<FormLabel margin="-4px 0 0 0" htmlFor="leadECG">
												Select 12 Lead ECG File
											</FormLabel>
											{!leadECG.length ?
												<FileUploader  	{...registerPatientForm('leadECG', {
												})}
													 onUploadStart={()=>{console.log('start')}}
													 showOver
													 onUploadEnd={(f: fileType[]) => {
														 setPatientValue('leadECG', f[0]);
														 setLeadECG(f);
													 }}
												/>
												:
												<Card>
													<Flex flex='1' gap='4' alignItems='center' flexDirection='row' p={4}>
														<Avatar icon={<AttachmentIcon />} />
														<Box>
															<Heading size='sm' mb="0">{leadECG[0] ? leadECG[0].fileName || leadECG[0].name : 'Lead ECG File'}</Heading>
															<Link
																display="flex"
																color="#1e70bf"
																justifyContent="flex-start"
																alignItems="center"
																target="_blank"
																rel="noopener noreferrer"
																href={files.leadECG.studyFileId ? `${SERVER_URL}/api/files/${files.leadECG.studyFileId}` : undefined}>
																<ExternalLinkIcon m='2px 2px 0 0' /> Preview File in new tab
															</Link>
														</Box>
														{canDeleteFiles && (
															<IconButton aria-label='Delete file' ml='auto'icon={<DeleteIcon />} onClick={()=>{deleteFile('leadECG')}}/>
														)}
													</Flex>
												</Card>}
										</FormControl>
									</Flex>

									<Flex>
										<FormControl
											display={selectedStudy && ['STRESS_ECHO','DOBUTAMINE_STRESS_ECHO'].includes(selectedStudy.studyType) ? 'flex' : 'none'}
											flexDir="column"
											isInvalid={!!patientFormErrors.stressEcg}
											width="100%"
											margin="8px 0px"
										>
											<FormLabel margin="-4px 0 0 0" htmlFor="stressEcg">
												Select Stress ECG File
											</FormLabel>
											{!stressEcg.length ?
												<FileUploader  	{...registerPatientForm('stressEcg', {
												})}
													 onUploadStart={()=>{console.log('start')}}
													 showOver
													 onUploadEnd={(f: fileType[]) => {
														 setPatientValue('stressEcg', f[0]);
														 setStressEcg(f);
													 }}
												/>
												:
												<Card>
													<Flex flex='1' gap='4' alignItems='center' flexDirection='row' p={4}>
														<Avatar icon={<AttachmentIcon />} />
														<Box>
															<Heading size='sm' mb="0">{stressEcg[0] ? stressEcg[0].fileName || stressEcg[0].name : 'Stress ECG File'}</Heading>
															<Link
																display="flex"
																color="#1e70bf"
																justifyContent="flex-start"
																alignItems="center"
																target="_blank"
																rel="noopener noreferrer"
																href={files.stressEcg.studyFileId ? `${SERVER_URL}/api/files/${files.stressEcg.studyFileId}` : undefined}>
																<ExternalLinkIcon m='2px 2px 0 0' /> Preview File in new tab
															</Link>
														</Box>
														{canDeleteFiles && (
															<IconButton aria-label='Delete file' ml='auto'icon={<DeleteIcon />} onClick={()=>{deleteFile('stressEcg')}}/>
														)}
													</Flex>
												</Card>}
										</FormControl>
									</Flex>
									{selectedStudy && acceptedModalStudyTypes.includes(selectedStudy.studyType) ?
										<Flex>
											<FormControl
												display="flex"
												flexDir="column"
												isInvalid={!!patientFormErrors.referral}
												width="100%"
												margin="8px 0px"
											>
												<FormLabel margin="-4px 0 0 0" htmlFor="referral">
													Select Referral File
												</FormLabel>
												{!referral.length ?
													<FileUploader  	{...registerPatientForm('referral', {
													})}
																	 onUploadStart={()=>{console.log('start')}}
																	 showOver
																	 onUploadEnd={(f: fileType[]) => {
																		 setPatientValue('referral', f[0]);
																		 setReferral(f);
																	 }}
													/>
													:
													<Card>
														<Flex flex='1' gap='4' alignItems='center' flexDirection='row' p={4}>
															<Avatar icon={<AttachmentIcon />} />
															<Box>
																<Heading size='sm' mb="0">{referral[0] ? referral[0].fileName || referral[0].name : 'Referral File'}</Heading>
																<Link
																	display="flex"
																	color="#1e70bf"
																	justifyContent="flex-start"
																	alignItems="center"
																	target="_blank"
																	rel="noopener noreferrer"
																	href={files.referral.studyFileId ? `${SERVER_URL}/api/files/${files.referral.studyFileId}` : undefined}>
																	<ExternalLinkIcon m='2px 2px 0 0' /> Preview File in new tab
																</Link>
															</Box>
															{canDeleteFiles && (
																<IconButton aria-label='Delete file' ml='auto'icon={<DeleteIcon />} onClick={()=>{deleteFile('referral')}}/>
															)}
														</Flex>
													</Card>}
											</FormControl>
										</Flex>
										:
										<Flex>
											<FormControl
												display="flex"
												flexDir="column"
												isInvalid={!!patientFormErrors.echoReferral}
												width="100%"
												margin="8px 0px"
											>
												<FormLabel margin="-4px 0 0 0" htmlFor="echoReferral">
													Select an Echo Referral File
												</FormLabel>
												{!echoReferral.length ?
													<FileUploader  	{...registerPatientForm('echoReferral', {
													})}
																	 onUploadStart={()=>{console.log('start')}}
																	 showOver
																	 onUploadEnd={(f: fileType[]) => {
																		 setPatientValue('echoReferral', f[0]);
																		 setEchoReferral(f);
																	 }}
													/>
													:
													<Card>
														<Flex flex='1' gap='4' alignItems='center' flexDirection='row' p={4}>
															<Avatar icon={<AttachmentIcon />} />
															<Box>
																<Heading size='sm' mb="0">{echoReferral[0] ? echoReferral[0].fileName || echoReferral[0].name : 'Echo Referral File'}</Heading>
																<Link
																	display="flex"
																	color="#1e70bf"
																	justifyContent="flex-start"
																	alignItems="center"
																	target="_blank"
																	rel="noopener noreferrer"
																	href={files.echoReferral.studyFileId ? `${SERVER_URL}/api/files/${files.echoReferral.studyFileId}` : undefined}>
																	<ExternalLinkIcon m='2px 2px 0 0' /> Preview File in new tab
																</Link>
															</Box>
															{canDeleteFiles && (
																<IconButton aria-label='Delete file' ml='auto'icon={<DeleteIcon />} onClick={()=>{deleteFile('echoReferral')}}/>
															)}
														</Flex>
													</Card>}
											</FormControl>
										</Flex>
									}

									<Flex>
										<FormControl
											display={selectedStudy && ['HOLTERTWENTYFOURHR','HOLTERFORTYEIGHTHR','HOLTEREXTENDED','AMBULATORY_BP','EVENT_MONITOR'].includes(selectedStudy.studyType) ? 'flex' : 'none'}
											flexDir="column"
											isInvalid={!!patientFormErrors.diary}
											width="100%"
											margin="8px 0px"
										>
											<FormLabel margin="-4px 0 0 0" htmlFor="diary">
												Select Diary
											</FormLabel>
											{!diary.length ?
												<FileUploader  	{...registerPatientForm('diary', {
												})}
													 onUploadStart={()=>{console.log('start')}}
													 showOver
													 onUploadEnd={(f: fileType[]) => {
														 setPatientValue('diary', f[0]);
														 setDiary(f);
													 }}
												/>
												:
												<Card>
													<Flex flex='1' gap='4' alignItems='center' flexDirection='row' p={4}>
														<Avatar icon={<AttachmentIcon />} />
														<Box>
															<Heading size='sm' mb="0">{diary[0] ? diary[0].fileName || diary[0].name : 'Diary File'}</Heading>
															<Link
																display="flex"
																color="#1e70bf"
																justifyContent="flex-start"
																alignItems="center"
																target="_blank"
																rel="noopener noreferrer"
																href={files.diary.studyFileId ? `${SERVER_URL}/api/files/${files.diary.studyFileId}` : undefined}>
																<ExternalLinkIcon m='2px 2px 0 0' /> Preview File in new tab
															</Link>
														</Box>
														{canDeleteFiles && (
															<IconButton aria-label='Delete file' ml='auto'icon={<DeleteIcon />} onClick={()=>{deleteFile('diary')}}/>
														)}
													</Flex>
												</Card>}
										</FormControl>
									</Flex>


									<ChakraButtonGroup mb="2" width="100%" justifyContent="space-between">
										<ChakraButton variant='ghost' onClick={()=> setIsUploadModalOpen(false)}>Cancel</ChakraButton>
										<ChakraButton colorScheme='blue' mr={3} type="submit">Confirm</ChakraButton>
									</ChakraButtonGroup>

								</Flex>
							</form>

							<form onSubmit={handleTestFormSubmit(onHolterReportSubmit)}>

								<Flex display={currentTab === 'TEST_DATA' ? 'flex' : 'none'} flexDirection="column">

									<Flex margin="8px 0 16px 0" flexDirection="column">
										<FormLabel>Status</FormLabel>

										<RadioGroup flexDirection="column" display="flex">
											<Radio
												{...registerTestForm('status', {
													required: true,
												})} value='AWAITING_CARDIOLOGIST_CONFIRMATION'>Upload Initial Test Data PDF, Awaiting Cardiologist Confirmation</Radio>
											<Radio
												{...registerTestForm('status', {
													required: true,
												})} value='CONFIRMED_HOLTER'>Upload Amended Test Data PDF, Cardiologist Changes Implemented</Radio>
										</RadioGroup>
									</Flex>
									<Flex>
										<FormControl
											display="flex"
											flexDir="column"
											variant="floating"
											isInvalid={!!testFormErrors.urgency}
											id="urgency"
											width="100%"
											margin="8px 0px"
										>

											<Select
												width="100%"
												height="42px"
												{...registerTestForm('urgency', {
													required: true,
												})}>
												<option key="Routine" value="Routine">Routine</option>
												<option key="Urgent" value="Urgent">Urgent</option>

											</Select>
											<FormLabel margin="-4px 0 0 0">Urgency</FormLabel>

										</FormControl>
									</Flex>
									<Flex>
										<FormControl
											display="flex"
											flexDir="column"
											isInvalid={!!testFormErrors.studyFile}
											width="100%"
											margin="8px 0px"
										>
											<FormLabel margin="-4px 0 0 0" htmlFor="studyFile">
												Select Test Data File
											</FormLabel>
											{!studyFile.length ?
												<FileUploader  	{...registerTestForm('studyFile', {
													required: true,
												})}
													 onUploadStart={()=>{console.log('start')}}
													 showOver
													 onUploadEnd={(f: fileType[]) => {
														 setTestValue('studyFile', f[0]);
														 setStudyFile(f);
													 }}
												/>
												:
												<Card>
													<Flex flex='1' gap='4' alignItems='center' flexDirection='row' p={4}>
														<Avatar icon={<AttachmentIcon />} />
														<Box>
															<Heading size='sm' mb="0">{studyFile[0] ? studyFile[0].name  || studyFile[0].fileName: 'File'}</Heading>
															<Link
																display="flex"
																color="#1e70bf"
																justifyContent="flex-start"
																alignItems="center"
																target="_blank"
																rel="noopener noreferrer"
																href={testData.testData.studyFileId ? `${SERVER_URL}/api/files/${testData.testData.studyFileId}` : undefined}>
																<ExternalLinkIcon m='2px 2px 0 0' /> Preview File in new tab
															</Link>
														</Box>
														{canDeleteFiles && (
															<IconButton aria-label='Delete file' ml='auto'icon={<DeleteIcon />} onClick={()=>{deleteFile('studyFile')}}/>
														)}
													</Flex>
												</Card>}
										</FormControl>
									</Flex>
									<ChakraButtonGroup mb="2" width="100%" justifyContent="space-between">
										<ChakraButton variant='outline' onClick={()=> setIsUploadModalOpen(false)}>Cancel</ChakraButton>
										<ChakraButton colorScheme='blue' mr={3} type="submit">Confirm</ChakraButton>
									</ChakraButtonGroup>

								</Flex>
							</form>

						</ModalBody>

					</ModalContent>
				</Modal>

				<Modal isOpen={isUpdateStudyModalOpen} onClose={() => {setIsUpdateStudyModalOpen(false)}} isCentered>
					<ModalOverlay />
					<ModalContent  maxH="100%" maxW="35%" minW="300px">

						<ModalHeader>Update Study</ModalHeader>
						<ModalCloseButton />
						<ModalBody  maxHeight="80vh" overflowY="auto">

							<form onSubmit={handleStudyFormSubmit(onStudyFormSubmit)}>

								<Flex flexDirection="column">
									<FormControl
										display="flex"
										flexDir="column"
										variant="floating"
										isInvalid={!!studyFormErrors.studyDate}
										width="100%"
										margin="8px 0px"
									>
										<Input
											width="100%"
											m="0"
											type="date"
											size="lg"
											{...registerStudyForm('studyDate', {
												required: true,
											})}
										/>
										<FormLabel margin="-4px 0 0 0" >Study Date</FormLabel>

									</FormControl>
									{selectedStudy &&
										<FormControl
											display="flex"
											flexDir="column"
											variant="floating"
											isInvalid={!!studyFormErrors.studyType}
											width="100%"
											margin="8px 0px"
										>
											<Select
												width="100%"
												height="42px"
												{...registerStudyForm('studyType', {
													required: true,
												})}>
												{holterTypes.includes(selectedStudy.studyType) && makeEnumFetchFunction(studyTypeOptions).filter(option => holterTypes.includes(option.value)).map(option => (<option key={option.value} value={option.value}>{option.display}</option>))}
												{echoTypes.includes(selectedStudy.studyType) && makeEnumFetchFunction(studyTypeOptions).filter(option => echoTypes.includes(option.value)).map(option => (<option key={option.value} value={option.value}>{option.display}</option>))}
												{ctTypes.includes(selectedStudy.studyType) && makeEnumFetchFunction(studyTypeOptions).filter(option => ctTypes.includes(option.value)).map(option => (<option key={option.value} value={option.value}>{option.display}</option>))}
											</Select>
											<FormLabel margin="-4px 0 0 0" >Study Type</FormLabel>

										</FormControl>
									}
									<FormControl
										display="flex"
										flexDir="column"
										variant="floating"
										isInvalid={!!studyFormErrors.accessionNumber}
										width="100%"
										margin="8px 0px"
									>
										<Input
											width="100%"
											m="0"
											size="lg"
											{...registerStudyForm('accessionNumber', {
												required: false,
											})}
										/>
										<FormLabel margin="-4px 0 0 0" >Accession Number</FormLabel>

									</FormControl>
									{/* {canEditStudyType && selectedStudy && (selectedStudy.report || selectedStudy.holterReport) ?
										<FormControl
											display="flex"
											flexDir="column"
											variant="floating"
											isInvalid={!!studyFormErrors.reportStatus}
											width="100%"
											margin="8px 0px"
										>
											<Select 
												width="100%"
												height="42px"
												{...registerStudyForm('reportStatus', {
													required: true,
												})}>
												{acceptedModalStudyTypes.includes(selectedStudy.studyType) && makeEnumFetchFunction(holterReportStatusOptions).map(option => (<option key={option.value} value={option.value}>{option.display}</option>)) }
												{!acceptedModalStudyTypes.includes(selectedStudy.studyType) && makeEnumFetchFunction(reportStatusOptions).map(option => (<option key={option.value} value={option.value}>{option.display}</option>)) }
											</Select>
											<FormLabel margin="-4px 0 0 0" >Report Status</FormLabel>
											
										</FormControl>
										: '' } */}

									<ChakraButtonGroup mb="2" width="100%" justifyContent="space-between">
										<ChakraButton variant='outline' onClick={()=> setIsUpdateStudyModalOpen(false)}>Cancel</ChakraButton>
										<ChakraButton colorScheme='blue' mr={3} type="submit">Confirm</ChakraButton>
									</ChakraButtonGroup>

								</Flex>
							</form>

						</ModalBody>

					</ModalContent>
				</Modal>
			</>
		) : <></>
	);
}));

export default StudyCrudTile;
